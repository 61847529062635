.transactions {
  border: 1px solid $color-gray;
  margin-bottom: 25px;
	position: relative;

  .transactions-filter {
    background: $color-gray;
    padding: 10px 15px;

		.col-xs-12 {
			margin-bottom: 10px;
		}

		input.search {
			background: #fff;
			border-top-left-radius: 6px;
			border-bottom-left-radius: 6px;
		}

		button[value='filter'].active {
			border-top-right-radius: 0;
			border-bottom-right-radius: 0;
		}

		.search-wrapper {
			display: flex;
			flex-wrap: wrap;

			> * {
				margin-bottom: 5px;
			}

			.input-group {
				max-width: 250px;
			}
		}

		.btn-group {
			display: flex;
			justify-content: center;

			> button {
				flex-grow: 1;
			}
		}

		.clear-input-field {
			right: 45px;
		}

		#date-filter {
			display: flex;
			align-items: center;
			justify-content: center;
			background: #fff;
			width: auto;
			border-top-right-radius: 6px;
			border-bottom-right-radius: 6px;

			input {
				text-align: center;
				background: transparent;
				border: 0;
				width: 80px;
			}

		}

		.export-search {
			display: flex;
			justify-content: space-between;
			align-items: center;

			button {
				color: $btn-default-color;
				background: transparent;
				border: 2px solid $btn-default-color;
				margin-right: 20px;
			}

			select {
				height: 40px;
				color: $btn-default-color;
				border: 2px solid $btn-default-color;
				font-weight: bold;
				border-radius: 6px;
				background-color: transparent;
				overflow: hidden;
			}
		}

  }

	// extended search
	.extended-search-toggle {
		background: #fff;
		color: inherit;
		margin: 0 10px;

		&[aria-expanded="true"] {
			opacity: .5;
		}
	}

	#extended-search {
		margin-top: 20px;
		position: relative;

		.close {
			font-size: 14px;
			position: absolute;
			top: 10px;
			right: 10px;
			color: $color-blue;
			opacity: 1;
		}
	}

  .transactions-inner {
    position: relative;
    overflow: hidden;
    height: 700px;

    #tx_table {
      height: 700px;
      overflow: hidden;
      overflow-y: scroll;
    }
  }

  .transactions-footer {
    line-height: 40px;
    border-top: 1px solid $color-gray;
  }

  .transactions-detailview, .transactions-detailedit {
    position: absolute;
    z-index: 100;
    background: #fff;
    top: 0;
    left: 0;
    width: 80%;
    border: 1px solid $color-gray;
    padding: 0 15px 15px 15px;
    box-shadow: 7px 7px 10px 0 rgba(0,0,0,0.2);
    font-size: 13px;

    @media (max-width: $screen-xs-max) {
      width: 100%;
    }

		.twingle-sync {
			background: $color-green;
			color: $white;
			padding: 3px;
			border-radius: 50%;
			font-size: 15px;

			&.disabled {
				background-color: $color-gray;
				color: $color-dark-gray;
			}
		}

		// details view header
		.header {
			border-bottom: 1px solid $color-medium-gray;
			margin-bottom: 15px;

			.detail-heading {
				margin-top: 0;
				margin-bottom: 0;
				font-size: 18px;
				font-weight: normal;
				padding: 10px 0 0 0;
			}

			.buttons {
				margin-right: -15px;

				a {
					line-height: 20px;
					display: block;
					cursor: pointer;
					float: right;
					padding: 15px;
					border-left: 1px solid $color-blue;
					font-weight: 500;
					font-size: 14px;

					&:not(.close-detail):hover {
						text-decoration: none;
						background: $color-blue;
						color: #fff;
					}

					&:first-child {
						padding-right: 0;
					}
				}
			}
		}

		#edit-debit-root {
			text-decoration: underline;
			font-weight: bold;
		}

		.close-detail {
			font-size: 20px;
			text-decoration: none;
			cursor: pointer;
		}

		.detail-name, h4 {
			color: $color-dark-gray;
			font-size: 12px;
			font-weight: normal;
		}

		.detail-value {
			font-size: 16px;
			overflow: hidden;

			i.right {
				line-height: inherit;
				float: right;
			}
		}

		.extra-field-hidden {
			display: none;
		}

		.extra-field.has-extra {
			display: block;
		}

		fieldset {
			border: 1px solid $color-dark-gray;
			margin-bottom: 10px;
			padding-bottom: 0;

			&.secondary {
				background: $color-input-bg;
			}

			.detail {
				padding: 10px;
				border-bottom: 1px solid $color-dark-gray;
			}

			> .detail:last-child, > .detail.last {
				border-bottom: 0;
			}

			> .row {
				border-bottom: 1px solid $color-dark-gray;

				.row {
					border-bottom: 0;
				}

				.detail {
					border-bottom: 0;
				}

				[class*=col-]:first-child .detail {
					border-right: 1px solid $color-dark-gray;
				}
			}

			.placeholder, ::placeholder {
				color: #999999;
			}

			.amount {
				font-size: 28px;
				padding-right: 5px;

				+ span {
					position: relative;
					top: -5px;
				}
			}

			.checkbox {
				margin-top: 0;
				margin-bottom: 0;

				label {
					cursor: auto;
				}
			}

			.comment {
				min-height: 40px;
				max-height: 120px;
				overflow: auto;
				font-size: 12px;

				hr {
					margin: 10px 0;
					border-top: 1px dashed $gray-light;

					&:last-child {
						display: none;
					}
				}
			}
		}

		// event donations
		&.event {
			&:before {
				@include twingleIcon();
				background: $gray-light;
				color: $white;
				content: "\e9a2";
				font-size: 20px;
				display: block;
				position: absolute;
				top: 0;
				left: 15px;
				padding: 20px 10px 10px 10px;
			}

			.detail-heading {
				padding-left: 55px;
			}

			.event-data {
				a {
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
					width: 100%;
					display: inline-block;
				}

				+ hr {
					border-color: $gray-light;
				}
			}

		}
  }

	// edit view of a transaction
	.transactions-detailedit {
		background: $color-very-light-blue;
		padding-bottom: 0;

		.hint {
			a {
				cursor: pointer;
			}
		}

		fieldset {
			.detail {
				background: $white;

				&.no-edit {
					background: $color-very-light-blue;
				}

				input, textarea {
					padding: 0;
					background: transparent;
					border: 0;
					box-shadow: none;
				}

				.amount-label {
					float: right;
					position: relative;
					top: 13px;
				}

				.recurring-amount-label {
					float: right;
					position: relative;
					top: 4px;
				}

				input[type="text"], input[type="number"] {
					height: auto;
					-webkit-appearance: none;
					-moz-appearance: textfield;
					appearance: textfield;

					// remove value spinner for number fields
					&::-webkit-outer-spin-button, &::-webkit-inner-spin-button {
						-webkit-appearance: none;
						margin: 0;
					}
				}

				[readonly] {
					background: transparent;
					color: $text-color;
				}

				.amount {
					width: 100%;
					display: block;
					text-align: right;
					background: transparent !important;
					padding-right: 3px;
				}

				select {
					width: 100%;
					background: transparent;
					border: 0;
					padding: 0;
				}
			}
		}

		.button-wrapper {
			background: $brand-primary;
			padding: 10px 0;
			margin-top: 20px;

			.checkbox {
				margin: 0;
				display: inline-block;
			}

			label {
				color: #fff;
				font-weight: normal;
				padding-right: 20px;
			}
			input:checked + label:before {
				color: #fff;
			}

			button:not(.btn-primary) {
				background: transparent;
				border: 1px solid #fff;
				color: #fff;
			}

			button.btn-primary {
				background: #fff;
				color: $brand-primary;
			}
		}

		#projects-selection {

		}
	}

	#info-wrapper {
		background: $brand-warning;
		color: $brand-warning-color;
		font-size: 16px;
		padding: 5px 0;
		margin-bottom: 15px;
		margin-top: -15px;

		p {
			margin: 0;
		}
	}

	// edit view of a transaction
	.transactions-detailedit {
		// hide helpblocks by default
		.help-block {
			display: none;
			margin: 0;
		}

		.has-error .help-block {
			display: block;
		}
	}

  .transactions-table {
    border: 0;
    margin: 0;

    > thead > tr > th:first-of-type,
    > tbody > tr > td:first-of-type {
      border-left: 0;
    }
    > thead > tr > th:last-of-type,
    > tbody > tr > td:last-of-type {
      border-right: 0;
    }

    > thead > tr > th,
    > tbody > tr > td {
      line-height: 27px;
			vertical-align: middle;
      border-color: $color-gray;
    }

    > thead > tr > th {
      font-weight: normal;
      text-align: center;
      border-bottom-width: 1px;
      color: #666;
	  font-size: 13px;
	  background: #e4e4e4;

      &.sort {
        cursor: pointer;
      }
    }

    tr.failed td.amount {
      text-decoration: line-through;
    }

		td.timestamp {
			text-align: left;

			i {
				font-size: 20px;
				color: $color-green;
				margin-top: 2px;
				margin-left: 5px;
				display: inline-block;
				float: right;
			}
		}

		td.user {
			i {
				font-size: 20px;
				float: right;
				margin-top: 4px;
				color: $color-blue;
			}
		}

		span.project {
			display: block;
			font-size: 9px;
			line-height: 11px;
			color: #999;
		}

    td.method {
      padding-left: 33px;
    }
	td.actions {
		text-align: center;
		padding: 4px 8px;
		.btn {
			color: $color-blue;
		}
		.dropdown-menu {
			right: 0;
			left: auto;
		}
	}
    tr.recurring td.method {
      padding-left: 8px;
    }

	span.search {
		background: yellow;
	}
		tfoot {
			> tr > td {
				border: 0;
				text-align: center;
				padding: 20px;
			}
		}

    @media (max-width: $screen-xs-max) {
      td.txnid {
        text-align: center;
      }
    }

    .confirm-toggle-faux,
    .confirm-toggle {
      cursor: pointer;
      text-decoration: none;
      i {
        border-radius: 50%;
        padding: 3px;
        background-color: #fff;
        color: $color-blue;
        font-size: 120%;
      }
      .twingle-check_checked {
        color: $color-green;
      }
    }
    .confirm-toggle-faux {
      cursor: default;
    }

    .error-toggle {
      cursor: pointer;
      text-decoration: none;
      i {
        font-size: 120%;
        color: $color-red;
      }
    }

    .twingle-sync {
      border-radius: 50%;
      padding: 4px;
      background-color: $color-green;
      color: #fff;
	  font-size: 12px;
    }

    .recurring-toggle {
      cursor: pointer;
      text-decoration: none;
      .disabled {
        background-color: $color-gray;
        color: $color-dark-gray;
      }
    }
  }

	.result-summary {
		margin: 0 40px;
		font-weight: bold;
	}
}


.table > tbody > tr.active {
  > td,
  .btn:focus,
  .btn.active,
  .btn {
    background-color: $color-light-blue;
    color: #fff;
  }
  .confirm-toggle i {
    background-color: #fff;
    border-radius: 50%;
  }
}

#bank_name {
	font-style: italic;
}


.transaction-pager {
	background: $color-gray;
	border-top: 1px solid $color-medium-gray;
	padding-top: 10px;

	&.bottom {
		padding: 20px 0;
	}

	button {
		border: 0;
		color: $color-blue;
	}

	select {
		border: 0;
		background: transparent;
	}
}

.filter-wrapper {

	&:last-of-type {
		margin-top: 20px;
	}

	> div {
		display: flex;
		flex-wrap: wrap;

		.checkbox-wrapper, .radio-wrapper {
			padding-right: 2px;
			margin-bottom: 2px;
			position: relative;
		}

		&:first-child {
			.checkbox-wrapper, .radio-wrapper {
				flex-grow: 1;
				min-width: unset;
			}

			label {
				border-radius: 6px 0 0 6px;
			}
		}

		.radio-wrapper:last-child {
			label {
				border-radius: 0 6px 6px 0;
			}
		}
	}

	label {
		background: #fff;
		margin: 0;
		height: 40px;
		padding: 10px;
		font-weight: normal;
		width: 100%;
	}

	input[type="checkbox"] {
		+ label {
			display: flex;
			align-items: center;
			padding-left: 30px;

			&:before {
				content: "\eaf8";
				font-size: 20px;
				top: 9px;
				left: 6px;
			}
		}

		&:checked + label {
			background: $color-blue;
			color: #fff;

			&:before {
				content: "\eaf6";
				color: #fff;
			}
		}
	}

	input[type="radio"] {
		+ label {
			text-align: center;

			&:before {
				display: none;
			}
		}

		&:checked + label {
			background: $color-blue;
			color: #fff;
		}
	}

}
