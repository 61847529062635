.statistic {
  border: 1px solid $color-gray;
  padding: 15px;
  margin-bottom: 25px;

  h4 {
    font-weight: normal;
  }

  .progress {
    background-color: $color-soft-green;
  }

  .progress-bar {
    background-color: $color-green;
  }

  .statistic-less {
    display: inline-block;
  }
  .statistic-more {
    display: none;
  }
}

.statistic.less {
  .statistics-view {
    display: flex;
    justify-content: space-between;

    .statistics-view-item {
      display: none;
      float: left;
      width: 30%;
    }
    .statistics-view-item:nth-child(-n+3){
      display: block;
    }
  }
  .statistic-less {
    display: none;
  }
  .statistic-more {
    display: inline-block;
  }
}


.project-settings-link {
  .header-wrapper {
		padding: 0 0 0 22px;
		background: #daedfb;
		color: #000000;
	  margin-bottom: 20px;
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: space-between;

		@media (max-width: $screen-sm-max) {
			padding: 0;
		}

	  h1 {
		  font-weight: normal;
		  font-size: 20px;
		  margin: 0;
			color: $color-darker-gray;

			@media (max-width: $screen-sm-max) {
				width: 100%;
				text-align: left;
				padding: 15px;
			}
	  }

		.button-wrapper {
			display: flex;

			@media (max-width: $screen-sm-max) {
				width: 100%;
				justify-content: space-between;
			}
		}

	  .btn {
		  background: transparent;
		  padding: 15px 20px;
		  border-radius: 0;
			text-decoration: none;
			border-left: 2px solid #fff;
			font-size: 14px;
			display: flex;
			height: 100%;

			@media (max-width: $screen-sm-max) {
				border-left: 0;
			}

			&:hover {
				text-decoration: none;
				background: $color-light-blue;
				color: #fff;
			}

			i.twingle-settings1 {
				margin-right: 10px;
			}

			i {
				font-size: 18px;
			}

			&[aria-expanded=true] {
				color: #fff;
				background: $color-blue;
			}
	  }

		.dropdown-wrapper {
			position: relative;
			text-align: left;

			ul {
				left: unset;
				right: 0;

				li {
					color: $color-blue;
					border-bottom: $color-gray 1px solid;

					&:last-child {
						border: 0;
					}

					&:hover {
						color: #fff;
					}

					a {
						color: inherit;

						i {
							padding-right: 5px;
						}
					}
				}
			}
		}
  }
}


