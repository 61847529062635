.image-wrapper {
	width: 100%;
	text-align: center;
	margin-bottom: 25px;

	img + button {
		padding: 5px !important;
		margin-top: 10px;
		display: inline-block;

		i {
			font-size: 18px;
		}
	}

	label {
		max-width: 95%;
		overflow: hidden;
		padding-right: 0 !important;
		border-right: 10px solid $color-blue;
	}

	&.empty {
		border: 1px solid $color-dark-gray;

		min-height: 150px;
		display: flex;
		align-items: center;
		justify-content: center;

		background:
			linear-gradient(to top left,
				rgba($color-dark-gray,0) 0%,
				rgba($color-dark-gray,0) calc(50% - 0.8px),
				rgba($color-dark-gray,1) 50%,
				rgba($color-dark-gray,0) calc(50% + 0.8px),
				rgba($color-dark-gray,0) 100%),
			linear-gradient(to top right,
				rgba($color-dark-gray,0) 0%,
				rgba($color-dark-gray,0) calc(50% - 0.8px),
				rgba($color-dark-gray,1) 50%,
				rgba($color-dark-gray,0) calc(50% + 0.8px),
				rgba($color-dark-gray,0) 100%);
	}


	input {
		display: none;
	}
}


.eventgallery .image-wrapper {
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;

	.project-image-remove {
		position: absolute;
		display: flex;
		justify-content: center;
		align-items: center;

		padding: 10px !important;
	}
}