$bar-gray: $color-gray;
$bar-green: #78bf03;
$bar-red: #d9001b;
$bar-yellow: #ffdb00;

#password-strength-validator {

  &[data-password-strength="red"], &[data-password-strength="yellow"], &[data-password-strength="green"] {
    .bar.bar-red {
      background-color: $bar-red;
    }
  }

  &[data-password-strength="yellow"], &[data-password-strength="green"] {
    .bar.bar-yellow {
      background-color: $bar-yellow;
    }

    .bar-label {
      color: $bar-yellow;
    }
  }

  &[data-password-strength="green"] {
    .bar.bar-green {
      background-color: $bar-green;
    }

    .bar-label {
      color: $bar-green;
    }
  }

  .bar {
    height: 5px;
    padding: 2px;
    border-radius: 3px;
    background-color: $color-gray;
  }

  .bar-label {
    margin-top: 9px;
    font-weight: 700;
    font-size: 14px;
    min-height: 20px;
    color: $bar-red;
  }
}