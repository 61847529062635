.salutation-table {
    .salutation-row {
        display: flex;

        & > :nth-child(1) {
            width: calc(40% + 22px);

            i {
                margin-right: 7px;
            }
        }

        & > :nth-child(2) {
            width: calc(60% - 22px);
        }

        & > span:nth-child(2) {
            display: flex;
            align-items: center;

            height: 39px;
            padding: 6px 12px;
            border: 1px solid $color-gray;
        }
    }

    .salutation-row + .salutation-row {
        margin-top: 8px;
    }

    .language-switch {
        margin-top: 20px;
    }

    .salutation-free-input {
        display: flex;
        margin-top: 0;
        align-items: center;

        input[type="text"] {
            width: calc(100% - 32px);
        }
    }
}
