#projects {
  text-align: center;
  margin-bottom: 25px;
}
#projects_dropdown {
	@include clearfix();

	margin-bottom: 20px;

	select {
		width: auto;
		min-width: 30%;
		float: left;
	}

	button {
		width: auto;
		float: left;
		background: $color-gray;
		border: none;
		margin-right: 20px;

		&:hover, &:focus, &:active {
			background: rgb(139, 208, 228);
			border-color: rgb(139, 208, 228);
			color: #fff;
		}
	}

	.chosen-container {
		border-radius: 6px;

		li {
			text-align: left;

			&.event {
				&:before {
					@include twingleIcon();
					content:"\e9a2";
					padding-right: 10px;
				}
			}
			&.shop {
				&:before {
					@include twingleIcon();
					content:"\e94d";
					padding-right: 10px;
				}
			}
			&.giftshop {
				&:before {
					@include twingleIcon();
					content:"\e953";
					padding-right: 10px;
				}
			}
			&.all {
				&:before {
					@include twingleIcon();
					content:"\e943";
					padding-right: 10px;
				}
			}
		}

		.chosen-single {
			text-align: left;
			background: $color-input-bg;
			border: none;
			box-shadow: none;
			border-radius: inherit;
			height: 36px;
			line-height: 36px;

			div b {
				background-image: url(/img/chosen-sprite.png);
				top: 5px;
				position: relative;
			}
		}
	}

	&.event {
		.chosen-container .chosen-single span:before {
			@include twingleIcon();
			content:"\e9a2";
			padding-right: 10px;
		}
	}
	&.shop {
		.chosen-container .chosen-single span:before {
			@include twingleIcon();
			content:"\e94d";
			padding-right: 10px;
		}
	}
	&.giftshop {
		.chosen-container .chosen-single span:before {
			@include twingleIcon();
			content:"\e953";
			padding-right: 10px;
		}
	}
	&.all {
		.chosen-container .chosen-single span:before {
			@include twingleIcon();
			content:"\e943";
			padding-right: 10px;
		}
	}
}

.btn-group.project-selector {
  .btn {
    font-weight: normal;

	  &.event {
		  &:before {
			  @include twingleIcon();
			  content:"\e9a2";
			  padding-right: 10px;
		  }
	  }

		&.shop {
			&:before {
				@include twingleIcon();
				content:"\e94d";
				padding-right: 10px;
			}
		}

		&.giftshop {
			&:before {
				@include twingleIcon();
				content:"\e953";
				padding-right: 10px;
			}
		}

		&.all {
			&:before {
				@include twingleIcon();
				content:"\e943";
				padding-right: 10px;
			}
		}
  }

  .btn:not(.active) {
    background: $color-gray;
    &:hover, &:focus, &:active {
      background: rgb(139, 208, 228);
      border-color: rgb(139, 208, 228);
      color: #fff;
    }
  }
}
