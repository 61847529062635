body.dragging,
body.dragging * {
  cursor: move !important;
}

.dragged {
  position: absolute;
  opacity: 0.5;
  z-index: 9000;
}

.drag-handle {
  display: inline-block;
  margin-right: 5px;
	cursor: move;
}


ol.sortable {
  position: relative;
}

ol.sortable li.placeholder {
  position: relative;
}

ol.sortable li.placeholder:before {
  position: absolute;
}

