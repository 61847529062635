
.organisations {
  .btn-default {
    background: $btn-default-bg;
    font-weight: normal;
    border: 1px solid transparent;
  }
  .btn-default:hover {
    background: $color-blue;
    color: #fff;
  }

  .create-button-container {
    i {
      padding-right: 10px;
    }
  }

	.input-daterange {
		white-space: nowrap;
		.date {
			width: 45%;
			display: inline-block;
		}
		.data-dash {
			width: 10%;
			display: inline-block;
			vertical-align: middle;
			text-align: center;
		}
		.date-label {
			padding-top: 10px;
		}
	}
}

.organisation-listing {
  margin-top: 25px;
}

.organisation-listing-item {
  margin-top: 4px;

  .btn-group {
    display: flex;
  }


  .btn-group .btn:first-child {
    width: 95%;
    border-right: 3px solid $color-dark-gray;
    text-align: left;
  }


  .btn-group .btn {
    background: $btn-default-bg;
    font-weight: normal;
  }
  .btn-group .btn:hover {
    background: $color-blue;
    border-right-color: $color-blue;
    color: #fff;
  }

  .btn-group .btn:not(:first-child) {
    background: #fff;
    border: 1px solid $btn-default-bg;
  }
  .btn-group .btn:not(:first-child):hover {
    color: $color-blue;
  }

}

#civi_crm_options, #custom_smtp_options, #microsoft_dynamics_nav_unitop_options, #microplan_options, #webhook_options, #gruen_evewa_options {
	margin-top: 30px;
}

.selfservice-data {
	margin-top: 20px;
}
