.navbar {
  border-bottom: 1px solid $color-gray;
  margin-bottom: 40px;
  border-width: 0;
  border-radius: 0;

  .navbar-brand {
    line-height: 50px;
    padding-top: 10px;
    padding-bottom: 5px;

		@media (min-width: $screen-sm-min) {
			&.back {
				padding-top: 18px;
			}
		}

    > img {
      max-height: 50px;
    }

    &:hover, &:focus, &:active {
      color: $color-blue;
    }
  }

  // mobile
  @media (max-width: $screen-xs-max) {
    border-bottom: 0;
    margin-bottom: 0;
    .navbar-header {
      border-bottom: 1px solid #999999;
    }
    .navbar-collapse {
      border-top: 0;
      border-bottom: 2px solid $color-gray;
    }
    .navbar-toggle {
      padding: 9px 12px 5px 12px;
      margin-bottom: 0;
      margin-top: 7px;
      border: 1px solid #999999;
      text-decoration: none;
      color: $text-color;

      &:focus, &:hover {
        background: #fff;
        color: $color-blue;
        text-decoration: none;
      }
    }
		.navbar-right {
			margin: 0;
		}
    .navbar-brand {
      padding-bottom: 0;
      padding-top: 4px;
      height: 50px;

      > img {
        max-height: 40px;
      }
    }
    .menu-item.hidden {
      display: block !important;
    }
    .navbar-nav > li > a > span {
      padding-left: 10px;
    }
    .dropdown-toggle {
      padding-top: 12px !important;
    }
    .navbar-nav > li .childorg {
      font-size: 14px;
      padding-left: 12px;
    }
  }
}

@media (min-width: $screen-sm-min) {
  .navbar-nav > li > a {
    padding-top: 21px;
    padding-bottom: 15px;

    i {
      font-size: 22px;
    }
  }


}

body.page_welcome {
  .navbar-nav > .menu-close {
    display: none;
  }
}

/*
|--------------------------------------------------------------------------
| Support Tab
|--------------------------------------------------------------------------
*/
.support-tab {
  background: $color-orange;
  width: 165px;
  position: absolute;
  z-index: 1500;
  top: 0;
  left: calc(55% - 150px);
  padding: 5px 15px;
  text-align: center;
  border-bottom-left-radius: $clickable-radius;
  border-bottom-right-radius: $clickable-radius;
  color: #fff;
  cursor: pointer;
  font-size: 12px;

  i {
    margin-right: 5px;
  }

  @media (max-width: $screen-xs-max) {
    display: none;
  }
}

#menu {
	@media (min-width: $screen-sm-min) {
		.navbar-header:not(.small) {
			margin-top: 16px;
		}
	}

  .navbar > .container-fluid {
    border-bottom: 1px solid #999;
  }
}

#mainmenu {
  margin-top: 40px;

	li:not(:last-child) {
		margin-right: 10px;
	}

  @media (max-width: $screen-xs-max) {
    margin-top: 5px;
  }

  @media (min-width: $screen-sm-min) {
		a {
			padding-top: 4px;
			padding-bottom: 4px;
		}

		li:not(.dropdown) {
			&.active {
				a {
					background: $color-blue;
					color: #fff;
					border-radius: 5px;
				}
			}

			a {
				margin-top: 5px;
			}

			a:hover, a.active {
				background: $color-blue;
				color: #fff;
				border-radius: 5px;
			}

    a.dropdown-toggle {
      padding-top: 10px;
      margin-top: -5 px;
		}
	}
}

}

#topmenu {
  position: absolute;
  top: 0;
  right: 14px;

  @media (max-width: $screen-xs-max) {
    position: relative;
  }

  a {
    padding-top: 6px;
    padding-bottom: 5px;
    cursor: pointer;

    @media (max-width: $screen-xs-max) {
      padding: 10px 15px;
      span {
        padding-left: 0;
      }
    }
    @media (min-width: $screen-sm-min) {
      a {
        font-size: 12px;
      }
    }
  }

  i {
		&:not(.twingle-X) {
			font-size: 12px;
		}

    @media (max-width: $screen-xs-max) {
      display: none
    }
  }

  .menu-logout {
    a {
			background:$color-medium-gray;
			border-bottom-left-radius: 5px;
			border-bottom-right-radius: 5px;

			&:hover {
				background: $color-blue;
				color: #fff;
			}
    }
  }

  .menu-username {
    a {
      padding-left: 5px;
      color: #777 !important;
    }
  }

  .menu-help {
    i {
      padding-right: 5px;
    }
  }

  .menu-support {
    @media (min-width: $screen-sm-min) {

      a {
        color: orange;
      }
      a:hover {
        color: #fff;
        background: orange;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
      }
    }
  }

}


