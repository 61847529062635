@mixin twingleIcon() {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'twingle' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-large {
  font-size: $font-size-large;
}

[class^="twingle-"], [class*=" twingle-"] {
	@include twingleIcon();
}
.twingle-help:before {
	content: "\e9d4";
}
.twingle-help-circle:before {
	content: "\e9d3";
}
.twingle-exit_B:before {
  content: "\e905";
}
.twingle-clock:before {
  content: "\e906";
}
.twingle-no:before {
  content: "\e908";
}
.twingle-alert_B:before {
  content: "\e90a";
}
.twingle-alert-triangle:before {
	content: "\e961";
}
.twingle-settings:before {
  content: "\e90b";
}
.twingle-exit:before {
  content: "\e90c";
}
.twingle-share:before {
  content: "\e90d";
}
.twingle-maximize:before {
  content: "\e90e";
}
.twingle-alert_shout_A:before {
  content: "\e90f";
}
.twingle-invoice_receipt:before {
  content: "\e910";
}
.twingle-twingle_button:before {
  content: "\e911";
}
.twingle-twingle_ohne:before {
  content: "\e912";
}
.twingle-twingle_alt:before {
  content: "\e913";
}
.twingle-calendar:before {
  content: "\e914";
}
.twingle-twitter:before {
  content: "\e915";
}
.twingle-facebook:before {
  content: "\e916";
}
.twingle-letter_dark:before {
  content: "\e917";
}
.twingle-letter_light:before {
  content: "\e918";
}
.twingle-chat_mobile:before {
  content: "\e919";
}
.twingle-chat:before {
  content: "\e91a";
}
.twingle-bank_transfer:before {
  content: "\e91b";
}
.twingle-Power_on_off:before {
  content: "\e91c";
}
.twingle-google-plus2:before {
  content: "\ea8c";
}
.twingle-instagram:before {
  content: "\ea92";
}
.twingle-twitter:before {
  content: "\ea96";
}
.twingle-sofortueberweisung:before {
  content: "\f03c";
}
.twingle-sepa:before {
  content: "\f04a";
}
.twingle-amex:before {
  content: "\f001";
}
.twingle-visa:before {
  content: "\f045";
}
.twingle-mastercard:before {
  content: "\f02d";
}
.twingle-diners:before {
  content: "\f013";
}
.twingle-enter:before {
  content: "\ea13";
}
.twingle-credit-card:before {
  content: "\e93f";
}
.twingle-paypal:before {
  content: "\ead8";
}
.twingle-paydirekt:before {
  content: "\e930";
}
.twingle-earth:before {
  content: "\e904";
}
.twingle-info:before {
  content: "\ea10";
}
.twingle-plus:before {
  content: "\ea0e";
}
.twingle-minus:before {
  content: "\ea0f";
}
.twingle-office:before {
  content: "\e907";
}
.twingle-pencil:before {
  content: "\e909";
}
.twingle-bullhorn:before {
  content: "\e91e";
}
.twingle-book:before {
  content: "\e923";
}
.twingle-profile:before {
  content: "\e927";
}
.twingle-files-empty:before {
  content: "\e929";
}
.twingle-file-text2:before {
  content: "\e92a";
}
.twingle-folder-download:before {
  content: "\e937";
}
.twingle-phone:before {
  content: "\e946";
}
.twingle-mobile:before {
  content: "\e95c";
}
.twingle-bubble:before {
  content: "\e96f";
}
.twingle-bubble2:before {
  content: "\e972";
}
.twingle-user:before {
  content: "\e975";
}
.twingle-users:before {
	content: "\e951";
}
.twingle-spinner:before {
  content: "\e97e";
}
.twingle-search:before {
  content: "\e98a";
}
.twingle-search1:before {
  content: "\e9a3";
}
.twingle-enlarge:before {
  content: "\e98d";
}
.twingle-lock:before {
  content: "\e993";
}
.twingle-unlocked:before {
  content: "\e994";
}
.twingle-equalizer:before {
  content: "\e996";
}
.twingle-cog:before {
  content: "\e998";
}
.twingle-pie-chart:before {
  content: "\e99e";
}
.twingle-stats-bars:before {
  content: "\e9a0";
}
.twingle-stats-bars2:before {
  content: "\e9a1";
}
.twingle-bin2:before {
  content: "\e9b1";
}
.twingle-power:before {
  content: "\e9b9";
}
.twingle-clipboard:before {
  content: "\e9bc";
}
.twingle-list2:before {
  content: "\e9bf";
}
.twingle-heart:before {
  content: "\e9de";
}
.twingle-smile:before {
  content: "\e9e5";
}
.twingle-ok:before {
  content: "\e921";
}
.twingle-sad:before {
  content: "\e9e9";
}
.twingle-neutral:before {
  content: "\e9fb";
}
.twingle-checkbox-checked:before {
  content: "\ea56";
}
.twingle-checkbox-unchecked:before {
  content: "\ea57";
}
.twingle-radio-checked:before {
  content: "\ea58";
}
.twingle-radio-unchecked:before {
  content: "\ea5a";
}
.twingle-share:before {
  content: "\ea81";
}
.twingle-stats-dots:before {
  content: "\e99f";
}
.twingle-facebook2:before {
  content: "\ea95";
}
.twingle-svg:before {
	content: "\eae9";
}
.twingle-sync:before {
  content: "\eaef";
}
.twingle-arrow_right_solo:before {
  content: "\eaf0";
}
.twingle-arrow_up_solo:before {
  content: "\eaf1";
}
.twingle-arrow_left_solo:before {
  content: "\eaf2";
}
.twingle-arrow_down_solo:before {
  content: "\eaf3";
}
.twingle-arrow_down_circle:before {
  content: "\eaf4";
}
.twingle-check_checked_negative:before {
  content: "\eaf5";
}
.twingle-check_checked:before {
  content: "\eaf6";
}
.twingle-circle_fine:before {
  content: "\eaf8";
}
.twingle-delete_X:before {
  content: "\eaf9";
}
.twingle-X:before {
  content: "\eafa";
}
.twingle-menue_burger_fine:before {
  content: "\e900";
}
.twingle-location-heart:before {
  content: "\e901";
}
.twingle-location-hole-outline:before {
  content: "\e902";
}
.twingle-location-hole:before {
  content: "\e903";
}
.twingle-seenotretter:before {
	content: "\e931";
}
.twingle-gift:before {
	content: "\e9a2";
}
.twingle-world:before {
	content: "\e93c";
}
.twingle-copy:before {
	content: "\e942";
}
.twingle-grid:before {
	content: "\e943";
}
.twingle-layers:before {
	content: "\e944";
}
.twingle-minus1:before {
	content: "\e945";
}
.twingle-plus1:before {
	content: "\e948";
}
.twingle-settings1:before {
	content: "\e949";
}
.twingle-tool:before {
	content: "\e94a";
}
.twingle-giropay:before {
	content: "\e94e";
}
.twingle-drag:before {
	content: "\e94c";
}
.twingle-drag_d:before {
	content: "\e9c7";
}
.twingle-message-square:before {
	content: "\e98c";
}
.twingle-check-circle:before {
	content: "\e96c";
}
.twingle-drag_c1:before {
	content: "\e9c8";
}
.twingle-icon_custom_medico_int:before {
	content: "\e94b";
}
.twingle-icon_custom_stiftungsbildung:before {
	content: "\e941";
}
.twingle-shopping-cart:before {
	content: "\e94d";
}
.twingle-eye_visible:before {
	content: "\e91f";
}
.twingle-eye_invisible:before {
	content: "\e91d";
}
.twingle-diploma1-outline:before {
	content: "\e952";
}
.twingle-legal-certificate-outline:before {
	content: "\e953";
}
.twingle-sprache_voll:before {
  content: "\f27a";
}
.twingle-matched:before {
	content: "\e96b"
}
.twingle-open:before {
	content: "\e9d4"
}
.twingle-ignore:before {
	content: "\e908"
}
.twingle-arrow-right-circle:before {
	content: "\e966";
}
