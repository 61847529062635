.alert {
  padding: 6px 12px;
}

.is-invalid {
	border-color: red !important;
}
.user-listing span.error-message {
	font-size: 12px !important;
}

.toggle-password-view {
  position: absolute;
  z-index: 10;
  margin-top: -30px;
  display: block;
  width: 30px;
  height: 20px;
  text-align: center;
  right: 0;
  cursor: pointer;
}

.toggle-password-view i:before {
  content: "\e91f";
}

.password-revealed {
  .toggle-password-view i:before {
    content: "\e91d";
  }
}



.clear-input-field {
  position: absolute;
  z-index: 10;
  right: 18px;
  top: 10px;
  display: block;
  width: 30px;
  height: 20px;
  text-align: center;
  cursor: pointer;
}

.form-control {
  background-color: $color-input-bg;
  border-color: $color-input-bg;

  border-radius: 0;
  box-shadow: none;

  &.big-letters {
	  text-transform: uppercase;
  }

	&.right {
		text-align: right
	}

  &.readonly:not(.date):not(.copy-on-click), &[readonly]:not(.date):not(.copy-on-click) {
	  background: #f2f2f2;
	  color: #ccc;
	  cursor: not-allowed;
  }

  &.copy-on-click {
  	color: $brand-primary;
  }

  &:focus {
    border-color: lighten($color-blue, 30%);
    box-shadow: inset 0 1px 1px $color-blue, 0 0 3px lighten($color-blue, 20%);
    outline: 0;
  }

	&.input-text-dummy {
		cursor:not-allowed;
		background: transparent;
		border: 1px solid #f2f2f2;
	}
}
.form-control::-webkit-input-placeholder {
  color: $text-color;
}
.form-control::-moz-placeholder {
  color: $text-color;
}
.form-control:-ms-input-placeholder {
  color: $text-color;
}

.subselection {
	padding-left: 26px;
}

.checkbox {
	clear: left;

	.inline-help {
		position: absolute;
		top: 0;
		right: 0;
	}
}


.checkbox label, .radio label {
  padding-left: 0;
}

.checkbox + .form-group, .radio + .form-group, .radio + .wrapper, .checkbox + .wrapper {
	margin-top: 25px;
}

.checkbox.single {
	padding: 0 10px;

	label:before {
		margin-top: 2px;
	}
}

.double-selection {
	.checkbox {
		border-bottom: 1px solid $color-gray;
		padding-top: 4px;
		padding-bottom: 4px;
	}

	.subselect {
		position: absolute;
		top: 0;
		right: -5px;

		input[type="checkbox"] {
			+ label {
				border: 1px solid $color-medium-gray;
				border-radius: 50%;
				display: inline-block;
				width: 24px;
				height: 24px;

				&:before {
					content: "";
				}
			}

			&:disabled + label {
				display: none;
			}

			&:checked + label {
				background: $color-blue;

				&:before {
					content: "\eae9";
					color: #fff;
					padding-left: 4px;
					padding-top: 2px;
				}
			}
		}
	}
}
.double-selection-legend {
	display: flex;
	justify-content: space-between;
	color: $color-dark-gray;
	font-size: .9em;
	border-bottom: 1px solid $color-gray;
	padding: 5px 0;

	span:last-child {
		margin-right: 3px;
	}
}

/*
|--------------------------------------------------------------------------
| Radio
|--------------------------------------------------------------------------
*/
.radio label {
  padding-left: 0;
}

input[type=radio] { display:none; } /* to hide the radio itself */
input[type=radio] + label:before,
.radio-dummy label:before {
  font-family: 'twingle' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  display: inline-block;
}

input[type=radio] + label:before,
.radio-dummy label:before {
	content: "\ea5a"; /* unchecked icon */
	letter-spacing: 7px; /* space between radio and label */
}
input[type=radio]:checked + label:before,
.radio-dummy.checked label:before {
	content: "\ea58"; /* checked icon */
	color: $color-blue;
}



/*
|--------------------------------------------------------------------------
| Checkboxes
|--------------------------------------------------------------------------
*/
.checkbox label,
.checkbox-dummy label {
  padding-left: 20px;
	position: relative;
}

input[type=checkbox] { display:none; } /* to hide the checkbox itself */
input[type=checkbox] + label:before,
.checkbox-dummy label:before {
  font-family: 'twingle' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
	position: absolute;
	top: 3px;
	left: 0;
  display: inline-block;
}

// disabled checkbox
input[type="checkbox"][disabled] + label {
	opacity: .4;
}
.checkbox[title] input[type="checkbox"] + label {
	cursor: help;
}

input[type=checkbox] + label:before,
.checkbox-dummy label:before {
	content: "\ea57"; /* unchecked icon */
	letter-spacing: 7px; /* space between checkbox and label */
}

input[type=checkbox]:checked + label:before,
.checkbox-dummy.checked label:before {
	content: "\ea56"; /* checked icon */
	color: $color-blue;
}

.checkbox-dummy label {
	opacity: .5;
	cursor: help;
}

p.error {
	color: $color-red;
}


/*
|--------------------------------------------------------------------------
| Prefix/Suffix Addons
|--------------------------------------------------------------------------
*/

.input-group-addon {
  border: 0;

	&.link {
		border: 1px solid #ccc;
		background: #fff;
		text-decoration: none;
	}
}

/*
|--------------------------------------------------------------------------
| Buttons
|--------------------------------------------------------------------------
*/
.btn {
  padding: 6px 16px;
  font-weight: normal;
  border-radius: $clickable-radius;
}

.btn-primary {
  background-color: $color-blue;
  border-color: $color-blue;

  &:hover {
    background-color: lighten($color-blue, 10%);
    border-color: lighten($color-blue, 10%);
  }

	&[disabled] {
		opacity: 1;
		background: $color-medium-gray;
		border-color: $color-medium-gray;
		color: $color-dark-gray;

		&:hover {
			opacity: 1;
			background-color: lighten($color-blue, 10%);
			border-color: lighten($color-blue, 10%);
			color: #fff;
		}
	}
}

.btn-outline-primary {
	background-color: transparent;
  border-color: $color-blue;

	&:hover {
		background-color: lighten($color-blue, 10%);
		border-color: lighten($color-blue, 10%);
		color: #fff;
	}
}

.btn-link, .btn-link:hover, .btn-link:focus, .btn-link:active {
  outline: 0;
  font-size: 12px;
	text-decoration: none;
}

.btn-link:hover, .btn-link:active {
	background: $color-blue;
	color: #fff;
}

.btn-gray {
	background: $color-medium-gray;
	border-color: $color-medium-gray;
	color: $color-darker-gray;

	&:hover {
		background: $color-blue;
		border-color: $color-blue;
		color: #fff;
	}
}


/*
|--------------------------------------------------------------------------
| Fieldsets
|--------------------------------------------------------------------------
*/

hr {
  border-bottom: 0;
  border-top: 1px solid $color-gray;
  margin-bottom: 30px;
  margin-top: 0;

	&.no-margin {
		margin-bottom: 0;
	}
}

legend {
  border-bottom: 0;
  border-top: 1px solid $color-gray;
  padding-top: 10px;
  margin-bottom: 15px;
}

fieldset {
  padding-bottom: 30px;

	h3 {
		font-weight: normal;
		font-size: 18px;
		padding-bottom: 5px;
		border-bottom: 1px solid $color-gray;
		margin-bottom: 30px;
	}
}

.placeholderhint {
	font-style: italic;
	cursor: pointer;

	&:hover, &:active, &:focus {
		text-decoration: none;
	}

	&:after {
		@include twingleIcon();
		content: "\eaf0";
		font-size: 10px;
		padding-left: 5px;
	}

	+ dl {
		@include clearfix;
		display: none;
		margin-top: 5px;
		margin-bottom: 40px;
		background: $color-input-bg;
		padding: 10px;
		border: 1px dashed $color-dark-gray;

		dt {
			padding-right: 5px;
			margin-bottom: 10px;

			&:first-child {
				margin-top: 0;
			}
		}

		dd {

		}
	}

	+ .subform {
		display: none;
	}

	&.active {
		&:after {
			content: "\eaf3";
		}

		+ dl, +.subform {
			display: block;
		}
	}
}

fieldset:first-of-type legend {
  border-top: 0;
  padding-top: 0;
}


/*
|--------------------------------------------------------------------------
| Button Groups
|--------------------------------------------------------------------------
*/
.btn-group {
  min-height: 37px;

  .btn {
    border-top-width: 0;
    border-bottom-width: 0;
    background: #fff;

    &:hover, &:focus, &:active {
      outline: 0;
      box-shadow: none;
      background: #fff;
      cursor: pointer;
    }
  }

  .btn:last-of-type {
    border-right-width: 0;
  }

  .btn:first-of-type {
    border-left-width: 0;
  }

  .active {
    border-color: $color-blue;
    background: $color-blue;
    color: #fff;
    box-shadow: none;

    &:hover, &:focus, &:active {
      outline: 0;
      color: #fff;
      border-color: $color-blue;
      background: $color-blue;
      cursor: default;
    }
  }
}


/*
|--------------------------------------------------------------------------
| Logo Upload
|--------------------------------------------------------------------------
*/
.logo-remove-btn {
  float: right;
}

.logo-preview {
  max-height: 80px;
}
.logo-preview[src] {
  margin-bottom: 10px;
}

.logo-upload {
  background: $color-input-bg;
  padding: 10px;
	position: relative;

	.btn {
		position: absolute;
		top: 10px;
		right: 10px;
		border-width: 0;
	}

	img {
		margin-bottom: 10px;
	}

	.title {
		position: absolute;
		top: 10px;
		left: 10px;
		background: rgba(255,255,255,0.8);
		padding: 5px;
	}

	&.preview {
		margin-bottom: 20px;

		img {
			margin-bottom: 0;
		}

		&:not(:first-child) {
			margin-top: -10px;
		}
	}
}

.logo-file-input {
  line-height: 1;
}



/*
|--------------------------------------------------------------------------
| Floating Labels
|--------------------------------------------------------------------------
*/
.form-group {
  position: relative;
  margin-bottom: 25px;
}

.form-group label {
  overflow: hidden;
  max-height: 0;
  position: absolute;
  top: 2em;
  opacity: 0;
  z-index: 0;
  font-size: 12px;
  font-weight: normal;
  line-height: 1.764705882em;
  vertical-align: middle;
  vertical-align: baseline;
  margin: 0;
  transition: max-height 0.5s ease, top 0.5s ease, opacity 0.5s ease;
}

.floating-value label,
.floating-value-always label,
.floating-focus label {
  top: -20px;
  opacity: 1;
  max-height: 18px;
}

.floating-value label,
.floating-value-always label {
  display: block;
}

.floating-focus label {
  color: $color-blue;
}

/*
|--------------------------------------------------------------------------
| Color Picker
|--------------------------------------------------------------------------
*/
.form-group.color-picker {
  background: $color-input-bg;
	position: relative;
	z-index: 1;

  @include clearfix();

  label {
    overflow: visible;
    max-height: 20px;
    margin-top: 2px;
    margin-bottom: 0;
    padding-left: 10px;
    float: left;
    line-height: 35px;
    display: inline-block;
    position: static;
    opacity: 1;
		width: calc(100% - 140px);
  }

  .input-group {
    width: 140px;
    float: right;

	  input {
		  padding-left: 5px;
		  padding-right: 3px;
	  }
  }

  .color-preview {
    display: block;
    width: 20px;
    height: 20px;
  }

  .help-block {
    clear: both;
    padding-left: 10px;
    font-size: 13px;
  }

  // colorpicker with switch
	&.switch {
		label {
			cursor: pointer;
			max-height: none;

			span {
				padding: 6px 0;
			}
			i {
				position: absolute;
				right: 150px;
				top: 12px;
			}
		}

		&.active {
			.switch {display: none;}
			.picker {display: table;}
		}
		&.inactive, &.show-switch {
			.switch {display: table;}
			.picker {display: none;}
		}

		.switch {
			min-height: 38px;
		}

		ul {
			list-style-type: none;
			margin: 0;
			padding: 0;
			width: 100%;
			height: 100%;
			position: absolute;
			z-index: 10;

			li {
				padding: 6px 12px;
				background: inherit;
				line-height: 1.6;

				&[data-switch="manual"] {display: none;}
			}
		}

		&.show-switch {
			z-index: 100;

			ul {
				li {
					cursor: pointer;
					background: darken($gray-lighter, 10%);

					&:hover {
						background: darken($gray-lighter, 15%);
					}

					&[data-switch="manual"] {
						display: block;
					}
				}
			}
		}
	}

}


/** 2fa form **/
form.twofa {
	margin-top: 40px;

	input {
		// avoid zooming on iOS
		font-size: 16px;
	}
}
