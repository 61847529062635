/**
 * Special Layout fpr Project and Organisation settings
 */
body.page_project, body.page_settings, body.page_organisation_settings {
	background: $color-gray;

	.navbar {
		background: transparent;
		border-bottom: $color-medium-gray 1px solid;
	}

	// horizontal navigations
	.settings-nav {
		margin-bottom: 40px;
		border: 1px solid #cccccc;
		border-radius: $clickable-radius;

		li {
			border-right: 1px solid $input-border;

			a, a:hover {
				padding: 8px 5px;
				cursor: pointer;
				color: $text-color;
			}

			a:hover {
				background: rgba(255,255,255,.5);
			}

			&.active > a {
				background: #fff;
			}

			&:first-child {
				a {
					border-radius: $clickable-radius 0 0 $clickable-radius;
				}
			}

			&:last-child {
				border-right: none;

				a {
					border-radius: 0 $clickable-radius $clickable-radius 0;
				}
			}
		}
	}

	fieldset {
		background: $white;
		padding: 15px 20px;

		&.small-margin {
			margin-top: 2px;
		}

		&.no-legend {
			padding-top: 30px;
		}

		legend, .legend {
			float: left;
			width: 100%;
			padding: 0;
			margin-bottom: 25px;
			border: none;
			position: relative;
			font-size: 18px;
			font-weight: 200;

			// stop floating with the next element
			+ * {
				clear: left;
			}

			&.form {
				line-height: 38px;
			}

			&.no-margin {
				margin-bottom: 0;
			}

			.form-group {
				margin-bottom: 0;
			}


			.inline-help {
				position: absolute;
				top: 0;
				right: 0;
			}
		}

		+ fieldset {
			margin-top: 20px;

			&.small-margin {
				margin-top: 2px;
			}

			&.no-margin {
				margin-top: 0;
			}
		}
	}

	// error messages in forms
	.error-message {
		color: $brand-danger;
		font-size: 16px;
		line-height: 1.2;
	}
	
	$methods: mbe banktransfer paypal betterpay konfipay saferpay sofortueberweisung generic apple_pay google_pay twint paydirekt stripe_creditcard stripe_apple_pay stripe_google_pay twint post_finance;
	@each $method in $methods {
		fieldset.methods .checkbox-dummy.#{$method} {
			display: none;
		}
		fieldset.methods.has_not_#{$method} {
			.checkbox.#{$method} {
				display: none;
			}
			.checkbox-dummy.#{$method} {
				display: block;
			}
		}
	}
}
