$screen-sm: 640px;
$screen-md: 780px;
$screen-lg: 1000px;

$container-desktop: 1200px;
$container-large-desktop: $container-desktop;

$clickable-radius: 6px;
$border-radius-base: 0px;
$border-radius-large: 0px;
$border-radius-small: 0px;

// Colors
$color-gray: #f2f2f2; // rgb(242, 242, 242)
$color-soft-green: #dfe5cb; // rgb(223, 229, 203)
$color-green: rgb(143, 181, 44); // hex: #8fb52c
$color-red: #CB4900; // hex: #fe3200
$color-blue: rgb(22, 162, 203); // hex: #16a2cb
$color-light-blue: rgb(91, 190, 240); // hex: #5bbef0
$color-very-light-blue: #e8f6fa; // rgb(232, 246, 250)
$color-orange: rgb(254, 154, 43); // hex: #fe9a2b
$color-input-bg: rgb(242, 242, 242); // hex: #f2f2f2
$color-dark-gray: rgb(153, 153, 153); // hex: #999
$color-darker-gray: #666; // rgb(102, 102, 102)
$color-medium-gray: #e3e3e3; // rgb(227, 227, 227)
$color-medium-gray2: #aaaaaa; // rgb(170, 170, 170)
$color-disabled: #d7d7d7; // rgb(215, 215, 215)
$focus-color: #10e2b0; // rgb(16, 226, 176)

// Brands
$brand-primary: $color-blue;
$brand-primary-background: #DBEEF9;
$brand-primary-hover: #0dcaf0;
$brand-info: #10E2B0; // rgb(142, 180, 203)
$brand-success: #63a103; // rgb(99, 161, 3)
$brand-warning: #FFBA00; // rgb(255, 251, 0)
$brand-warning-color: #ff00c1; // rgb(255, 0, 193)
$brand-danger: $color-red; // rgb(191, 83, 41)

// Typography
$icon-font-path: "../fonts/";
$font-family-sans-serif: "Fira Sans", "Helvetica Neue", "Helvetica", sans-serif;
$font-size-large: 18px;
$font-size-base: 16px;
$font-size-small: 14px;
$font-size-smaller: 13px;
$font-size-small-label: 12px;
$line-height-base: 1.6;
$line-height-small: 1.2;
$text-color: rgb(99, 102, 100); // hex: #636664
$text-muted: rgb(188, 194, 197); // hex: #bcc2c5
$link-hover-color: $color-blue;

// Body
$body-bg: #fff;

// map colors to bootstrap 5 names
$primary: $color-blue;
$secondary: $color-very-light-blue;
$success: $brand-success;
$danger: $brand-danger;
$signal: $brand-warning-color;
$body-color: $text-color;
$light: $color-medium-gray;
$lighter: $color-gray;
$dark: $color-darker-gray;
$highlight: #b9ffff; // rgb(185, 255, 255)
$info: $brand-info;

// bootstrap 5 settings
$form-check-input-width: 1.2em;
$modal-inner-padding: 30px;
$modal-header-padding: 30px;
$navbar-brand-font-size: $font-size-base;
$navbar-light-color: $text-color;
$navbar-light-brand-color: $text-color;
$dropdown-item-padding-x: 0;
$dropdown-item-padding-y: 0.5em;
$dropdown-font-size: $font-size-small;
$input-btn-font-size: $font-size-small;
$dropdown-link-hover-bg: $brand-primary-hover;
$dropdown-link-hover-color: white;

// custom componenets
$field-view-padding: 10px;
$field-view-label-color: $color-dark-gray;

// Navbar
$navbar-height: 70px;
$navbar-default-bg: #fff;
$navbar-default-link-hover-color: $color-blue;
$nav-link-padding-y: 5px;
$nav-link-padding-x: 1rem;
$navbar-nav-link-padding-x: 15px;
$navbar-nav-link-padding-y: 15px;
$nav-link-color: $color-darker-gray;

// Panels
$panel-bg: $color-gray;
$panel-default-heading-bg: #fff;

// Buttons
$btn-default-bg: $color-gray;
$btn-default-color: $text-color;

// Inputs
$input-border: lighten($text-color, 40%);
$input-border-focus: lighten($brand-primary, 25%);
$input-color-placeholder: lighten($text-color, 30%);
$input-placeholder-color: lighten($text-color, 30%);

// Borders
$laravel-border-color: darken($body-bg, 10%);
$list-group-border: $laravel-border-color;
$navbar-default-border: $laravel-border-color;
$panel-default-border: $laravel-border-color;
$panel-inner-border: $laravel-border-color;

// Pager (Date-Range)
$pager-border-radius: 0;
$pager-bg: transparent;
$pager-border: transparent;

// Progressbar
$progress-bar-color: #fff;
$progress-bar-bg: $color-dark-gray;

// Popover
$popover-arrow-color: $color-green;
$popover-arrow-outer-fallback-color: $color-green;
$popover-arrow-outer-color: $color-green;

// Modal
$modal-md: 600px;
$modal-content-border-radius: 0;
$modal-content-box-shadow-xs: 0 3px 9px rgba(0, 0, 0, 0.5);
$modal-content-box-shadow-sm-up: 0 5px 15px rgba(0, 0, 0, 0.5);

// Buttons
$btn-link-hover-color: white;

// Toasts
$toast-header-background-color: rgba(white, 0.7);
$toast-header-color: $color-darker-gray;

$toast-background-color: rgba(white, 0.8);
$toast-color: $color-darker-gray;

// Table
$table-color: $color-darker-gray;

// Toggle
$toggle-height: 28px;
$toggle-width: 36px;

// tooltip
$tooltip-notice-bg: #10e2b0;
$tooltip-error-bg: #cb4900;

:root {
    --bundle-product-height: 50px;
}

// alert
$alert-info: $brand-info;
$alert-success: $brand-info;
$alert-danger: $brand-danger;
$alert-warning: $brand-warning;
$alert-hover: lighten($brand-primary, 10%);
