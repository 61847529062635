/**
 * Login Manager
 */
.user-listing-item {

  td {
    vertical-align: middle !important;
  }

  .toggle_login_manage {
    font-size: 18px;
		border: 2px solid $text-muted;
		color: $text-muted;
		border-radius: 50%;
		padding: 5px;
		width: 33px;
		height: 33px;
		display: flex;
		align-items: center;
		justify-content: center;

		&.text-success {
			border-color: $brand-success;
			color: $brand-success;
		}

		&:hover, &.text-success:hover {
			text-decoration: none;
			background: $brand-success;
			color: #fff;
			border-color: $brand-success;
		}
  }

  .edit-btn {
    outline: 0;
    box-shadow: none;
  }

  .user-value {
    display: block;
  }
  .user-control {
    display: none;
  }

  .user-control .form-control {
    background: #fff;
    box-shadow: none;
  }

  td.text-right {
    vertical-align: top !important;
  }

  &.selected {
    td {
      background: rgb(240, 251, 255);
      vertical-align: top !important;
    }


    .user-value {
      display: none;
    }
    .user-control {
      display: block;
    }
  }
}
