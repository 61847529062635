.daterange-selector {
  margin-bottom: 15px;

  .btn-group > .btn:not(.active):focus,
  .btn-group > .btn:not(.active):hover {
    border-color: $color-light-blue;
    background-color: $color-light-blue;
    color: #fff;
  }
}

.date-paginator {
  li {
    line-height: 1;
  }

  li > a, li > span {
    padding: 2px 12px 0 12px;
  }

  &.all .btn {
    visibility: hidden;
  }
}