.project-settings {

	.rhythm_options {
		padding-left: 25px;
	}

	.purpose-none {
		border-style: solid;
		border-color: $color-input-bg;
		border-width: 2px 0;
		padding: 10px 0 7px 0;
	}

	.order-list {
		margin-top: -10px;
		margin-bottom: 25px;

		li.placeholder {
			background: $color-input-bg;
			height: 41px;
		}
	}

	.order-item {
		border-bottom: 2px solid $color-input-bg;
		padding: 10px 30px 7px 0;
		position: relative;

		// optional internal id
		small {
			font-size: 12px;
			display: block;
			font-style: italic;
			padding-left: 23px;
		}

		.btn {
			position: absolute;
			right: 0;
			top: 50%;
			margin-top: -15px;
			padding: 2px 6px;
			color: darken($color-gray, 10);
			text-decoration: none;
			font-size: 15px;
		}
		.btn:hover {
			color: $color-blue;
		}
	}

	// with sortable class (while dragging)
	.order-item.dragged {
		position: absolute;
	}

	.thankyou-order-list {
		.btn {
			color: $color-darker-gray;
		}
	}

	// optional purpose id field, is not visible in default
	.new-purpose-id {
		background: lighten($color-gray, 5);
		border-width: 2px;

		&:not([style*="display: none;"]) {
			+ .input-group-btn .btn {
				height: calc(2 * #{$input-height-base});
			}
		}
	}

	.target {
		input[type=number]::-webkit-inner-spin-button,
		input[type=number]::-webkit-outer-spin-button {
			-webkit-appearance: none;
			margin: 0;
		}
	}

	// attachments
	.attachment-new-wrapper {
		margin-top: 40px;
	}

	.webintegration {
		padding-top: 15px;
	}

	.project-target {

	}

	.project-target-more {
		padding-left: 62px;
	}

	.delete-project {
		background: $color-dark-gray;
		color: #fff;
	}

	// slider and button configuration for the amount
	.donationvalue {
		.error-message {
			margin-bottom: 20px;
		}

		.row {
			> div {
				padding-left: 5px;
				padding-right: 5px;

				&:first-child {
					padding-left: 15px;
				}
				&:last-child {
					padding-right: 15px;
				}

				@media (min-width: $screen-sm-min) and (max-width: $screen-md-max) {
					&:nth-child(2n) {
						padding-right: 15px;
					}
					&:nth-child(2n+1) {
						padding-left: 15px;
					}
				}
			}

			input {
				padding-left: 7px;
				padding-right: 7px;
			}
		}
	}

	#slider-icon, #gift-donation-icon {
		font-family: 'twingle';
		text-align-last: center;
		text-align: center;

		option {
			text-align-last: center;
			text-align: center;
		}
	}

	.chosen-container {
		font-family: 'twingle', 'Fira Sans', sans-serif;
		width: 100% !important;

		li {

			text-align: center;
		}

		.chosen-single {
			text-align: center;
			background: $color-input-bg;
			border: none;
			box-shadow: none;
			border-radius: 0;
			height: $input-height-base;
			line-height: $input-height-base;

			div b {
				background-image: url(/img/chosen-sprite.png);
				top: 5px;
				position: relative;
			}
		}
	}

	#extrafield-wrapper {
		margin-top: 30px;
	}

	// switch for embed form
	#embed-switch {
		margin-bottom: 5px;
	}

	.warn {
		color: red;
	}

	// Logo wrapper for showing the orga-logo
	#logo-wrapper {
		position: relative;

		// has a logo
		&.has-logo {

			// logo should be hidden
			&.logo-hidden {
				&:after {
					font-family: 'twingle';
					content: "\e91d";
					color: #f10022;
					text-align: center;
					font-size: 60px;
					line-height: 60px;
					position: absolute;
					top: 0;
					left: 0;
					bottom: 0;
					right: 0;
					background: rgba(white, .8);
				}
			}

			.warn {
				display: none;
			}
		}

		// no-logo
		&.no-logo {
			+ .checkbox {
				opacity: .5;
			}
		}
	}

	// iframe for the counter
	iframe {
		width: 100%;
		height: 80px;
		border: 0;
		overflow: hidden;
		margin-bottom: 20px;
	}

	.events {
		.event {

			h4 {
				margin-bottom: 5px;
			}

			h4 + .form-group {
				margin-top: 30px;
			}

			small {
				display: block;
				font-size: 14px;
				margin-top: 20px;
				line-height: 1.3;
				color: #999;
			}
			.isPublic, .isNotPublic {
				font-size: 14px;
				line-height: 24px;
				color: #97B51B;
				display: inline-block;

				i {
					font-size: 24px;
					padding-right: 10px;
					float: left;
				}
			}
			.isNotPublic {
				color: #CC0000;
			}

			.btn {
				padding: 5px 16px;
				position: relative;
				top: -4px;
			}
		}
	}

	// Booking text
	.booking-texts {
		.flexbox-wrapper {
			input + span {
				min-width: 40px;
				text-align: center;
			}
		}

		.inline-help {
			color: $color-blue;
		}
	}

	// shop
	fieldset.categories, fieldset.products {
		.row.headline, span.headline {
			font-size: 12px;
			color: $color-medium-gray2;

			[class^="col"] {
				padding-left: 10px;
				padding-right: 10px;
			}
		}

		hr {
			margin-left: -5px;
			margin-right: -5px;
		}

		.row.category {
			padding-top: 5px;
			padding-bottom: 5px;

			.form-group {
				margin-bottom: 0;
				margin-top: 0;
			}

			[class^="col"] {
				padding-left: 10px;
				padding-right: 10px;
			}
		}

		.row.product {
			padding-top: 30px;
			padding-bottom: 10px;

			[class^="col"] {
				padding-left: 10px;
				padding-right: 10px;
			}

			.categories .checkbox:first-child {
				margin-top: 0;
			}

			.product-toggle-active {
				white-space: nowrap;
				cursor: pointer;

				&:hover {
					text-decoration: none;
				}

				.status {
					color: $color-red-error;

					&.active {
						color: $color-green-success;
					}
				}
			}

			hr:not(.no-margin) {
				margin: 15px 0;
			}

			.hint {
				margin-top: 8px;
				margin-bottom: 0;
			}

			// digital downloads
			span.headline {
				margin-top: 25px;
				display: block;

				+ hr {
					margin-bottom: 10px;
				}
			}

			i.btn {
				float: right
			}

			input[type="file"] {
				display: none;
			}
		}

		.btn-sm {
			padding: 5px 10px;
			font-size: 14px;
			line-height: 1;
		}
	}

	.custom-languages {
		table {
			width: 100%;
			margin-bottom: 30px;

			th {
				font-weight: normal;
				color: #999;
				font-size: .9em;
			}
			th, td {
				padding: 5px 0;

				&:last-child {
					text-align: right;
				}
				&:nth-child(2) {
					text-align: center;
				}
			}

			tr {
				border: {
					width: 0 0 1px 0;
					style: solid;
					color: #ccc;
				}
			}

			a {
				color: #d9d8d8;
				font-size: 15px;
				display: inline-block;
				margin-left: 10px;

				&:hover {
					color: $brand-primary;
					text-decoration: none;
				}
			}
		}

		strong {
			margin-bottom: 10px;
			display: block;
		}

		.subform {
			display: none;
			padding: 24px 10px 10px 10px;
			border: 1px solid #ccc;
			margin-bottom: 20px;

			.form-group:last-child {
				margin: 0;
			}

			p {
				margin-bottom: 0;
			}
		}

		p.error {
			margin-top: -10px;
			margin-bottom: 30px;
		}

		table a {
			cursor: pointer;
		}
	}

	// bonus settings
	.bonus-wrapper {
		.checkbox {
			margin-top: 0;
			margin-bottom: 25px;
		}
	}

}

body .placeholderhint + *, body .project-settings .placeholderhint + * {
	display: none;
}
body .placeholderhint.active + *, body .project-settings .placeholderhint.active + * {
	display: block;

	&.empty {
		display: flex;
	}
}
