body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  font-size: 14px;
}

h1,h2,h3,h4,h5,h6 {
  margin-top: 0;
}

#app {
  max-width: 1200px;
  margin: 0 auto;
}

#login_screen form {
  padding-top: 20px;
}

#login_screen .password-reset {
  margin-top: -20px;
  @media (max-width: $screen-xs-max) {
    margin-top: 10px;
    text-align: center;
  }
}

.page-header {
  border-bottom: 1px solid $color-gray;
  .detail-heading {
    font-size: 25px;
    font-weight: normal;
    margin-bottom: 0;
  }

  @media (max-width: $screen-xs-max) {
    margin-top: 10px;
  }
}

.modal {
  z-index: 9001;
}
.modal-backdrop {
  z-index: 9000;
}
.modal-body {
  padding: 30px;
}
.modal-header {
  border-bottom: 0;
}
.modal-footer {
  padding: 30px;
  border-top: 0;
}
@media (min-width: 768px) {
  .modal-dialog {
    margin: 60px auto;
  }
}


#loading {
  position: fixed;
  background: rgba(255, 255, 255, 1);
  z-index: 8000;

  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  height: 100%;
  width: 100%;

  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;

  opacity: 1.0;
  transition: opacity 0.6s ease;

  &.done {
    opacity: 0;
  }

	img {
		max-width: 200px;
		height: auto;
	}
}

@keyframes spin {
	0% { transform: rotate(0deg); }
	100% { transform: rotate(360deg); }
}

#spinner {
  position: fixed;
  background: rgba(255, 255, 255, 0.68);
  z-index: 8000;

  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  height: 100%;
  width: 100%;

  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;

	&:after {
		content: "";
		display: block;
		border: 16px solid #f3f3f3;
		border-top: 16px solid #16a2cb;
		border-radius: 50%;
		width: 120px;
		height: 120px;
		animation: spin 500ms linear infinite;
		position: absolute;
		top: 50%;
		left: 50%;
		margin-left: -60px;
		margin-top: -60px;
	}
}

.pace {
  -webkit-pointer-events: none;
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;

  left: 0;
  right: 0;
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
  position: fixed;
  z-index: 2000;
}

.pace-inactive {
  display: none;
}

.pace .pace-progress {
  display: none;
}

.pace .pace-progress-inner {
  display: none;
}

.pace .pace-activity {
  display: block;
  position: absolute;
  top: 85px;
  right: 23px;
  width:  36px;
  height: 36px;
  border: solid 5px transparent;
  background: #fff;
  border-top-color:  $color-blue;
  border-left-color: $color-blue;
  border-radius: 36px;
  -webkit-animation: pace-spinner 400ms linear infinite;
  -moz-animation: pace-spinner 400ms linear infinite;
  -ms-animation: pace-spinner 400ms linear infinite;
  -o-animation: pace-spinner 400ms linear infinite;
  animation: pace-spinner 400ms linear infinite;
}

@-webkit-keyframes pace-spinner {
  0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}
@-moz-keyframes pace-spinner {
  0% { -moz-transform: rotate(0deg); transform: rotate(0deg); }
  100% { -moz-transform: rotate(360deg); transform: rotate(360deg); }
}
@-o-keyframes pace-spinner {
  0% { -o-transform: rotate(0deg); transform: rotate(0deg); }
  100% { -o-transform: rotate(360deg); transform: rotate(360deg); }
}
@-ms-keyframes pace-spinner {
  0% { -ms-transform: rotate(0deg); transform: rotate(0deg); }
  100% { -ms-transform: rotate(360deg); transform: rotate(360deg); }
}
@keyframes pace-spinner {
  0% { transform: rotate(0deg); transform: rotate(0deg); }
  100% { transform: rotate(360deg); transform: rotate(360deg); }
}


// row without gutters
.row-no-gutters {
	margin-left: 0;
	margin-right: 0;

	[class*=col-] {
		padding-right: 0;
		padding-left: 0;
	}
}

span.time {
  font-size: 0.9em;
  color: #666;
  padding-left: 10px;
}

.hideit {
	opacity: 0;
	cursor: inherit;
}

.global-hint {
	display: block;
	background: $brand-primary;
	padding: 10px;
	color: white;
	margin-bottom: 20px;
	border-radius: 6px;

	a {
		color: inherit;
		text-decoration: underline;

	}
}

.loading {
	&:after {
		display: inline-block;
		content: '';
		margin-left: 5px;
		vertical-align: -1px;
		height: 15px;
		width: 15px;
		border: 3px solid lighten(#000, 60);
		border-right-color: transparent;
		border-radius: 50%;
		animation: rotate-loading 1.5s linear 0s infinite normal;
	}

	&.loaded {
		display: none;
	}
}