

#exception {

  text-align: center;

  padding-top: 20px;
  padding-bottom: 50px;

  hr {
    width: 50%;
    margin: 15px auto;
  }

  .exception-header {
    color: #333;
    font-size: 32px;
  }

  .exception-message {
    font-size: 15px;
  }

  .exception-text {
    display: block;
  }

  .exception-outro {
    line-height: 1.2;
  }

}