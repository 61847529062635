.progressbar-view {

  .progress-title {
    font-size: 12px;
  }

  .progress {
    border-radius: 15px;
    height: 26px;
  }

  .progress-bar, .progress-label, .progress-goal {
    font-size: 14px;
    font-weight: bold;
    line-height: 26px;
  }

  .progress-bar {
    position: relative;
    transition: none;
    border-radius: 15px;
  }

  .progress-label {
    right: 10px;
    top: 0;
    position: absolute;
  }

  .progress-goal {
    color: $color-dark-gray;
    position: absolute;
    right: 5px;
    top: 0;
  }

  .progress-wrap {
    position: relative;

    &.progress-label-outside .progress .progress-label {
      text-align: left;
      color: $text-color;
    }

    &.progress-goal-outside .progress-goal {
      color: $color-dark-gray;
      padding-right: 4px;
      top: -22px;
      right: 0;
    }
  }

}