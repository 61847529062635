.organisation-settings {


  .nav-pills a {
    @include clearfix();
    display: block;
    border-radius: 0;
    border-bottom: 1px solid rgb(228, 228, 228);
    cursor: pointer;
    margin: 0;
    color: $text-color;

    &:hover, &:focus {
      background-color: $color-light-blue;
      color: #fff;
    }
  }

  .nav-stacked > li + li {
    margin-top: 0;
  }

  .nav-pills a i {
    margin-top: 4px;
    margin-right: -5px;
    float: right;
  }


  .payment_options {

    > .checkbox label {
      font-weight: bold;

      i {
        margin-right: 5px
      }
    }

    fieldset {
      padding-left: 0;
      padding-right: 0;
      padding-top: 15px;

      .error-message {
        margin-top: -20px;
        margin-bottom: 20px;
        display: none;
      }

      &.has-error .error-message {
        display: block;
      }

      label {
        font-weight: normal;
      }
    }
  }

	.invoices {
		table {
			width: 100%;

			td, th {
				padding: 3px 2px;

				&:last-child {
					text-align: right;
				}
			}
		}
	}

}

.payment_methods {
  li {
    padding: 4px 14px 0 14px;
    border: 1px solid $color-gray;
    margin-bottom: 10px;
    border-radius: 7px;
  }

  fieldset {
    display: none;
  }

  .btn {
    padding: 3px 16px;
    font-weight: normal;
    border-radius: 3px;
  }

  .payment-activate {
    display: none;
    padding-bottom: 6px;
  }

  .checked .payment-activate {
    display: block;
  }

  .payment-cta {
    padding-bottom: 12px;

    a {
      text-decoration: underline;
    }
  }

  .active .payment-activate {
    display: none;
  }

  .active .payment-cta {
    display: none;
  }

  .checked fieldset {
    display: block;
  }
}

.konfipay_iban {
  padding-bottom: 20px;
  margin-top: -9 px;
}
