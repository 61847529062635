.flexbox-wrapper {
	display: -webkit-box;
	display: -moz-box;
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;
	align-content: stretch;
	justify-content: flex-start;

	input {
		flex: 1;
		min-width: 10px; /* workaround for inputs in flex container */
		width: 0; /* workaround for inputs in flex container */
	}

	span {
		flex: 0 1 auto;
		white-space: nowrap;
		height: $input-height-base;
		line-height: $input-height-base;
		padding: 0 5px;
		color: $color-dark-gray;
		border: 2px solid $color-input-bg;
	}

	span + span {
		border-left-width: 0;
	}

	.has-error & {
		padding-top: 25px;

		.error-message {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
		}
	}
}
