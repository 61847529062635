
.radial-progress {
  padding: 10px;
  cursor: pointer;
  border-radius: $clickable-radius;

  padding-bottom: 20px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;

  &:hover, &.active {
    background-color: #fff;
  }

  .radial-title {
    text-align: center;
    color: $color-green;
  }

  .radial-container {
    padding: 5px 40px;

    @media (max-width: 1000px) {
      padding: 5px 20px;
    }

    @media (max-width: 880px) {
      padding: 5px 10px;
    }


    @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
      padding: 5px;
    }

    @media (max-width: $screen-xs-max) {
      padding: 0px;
    }

    .progressbar-text {
      text-align: center;
      font-weight: 700;
      line-height: 1.2;
    }

    .value {
      font-size: 150%;
      padding-top: 2rem;
    }

    .subline {
      font-size: 90%;
    }

  }
}

.line-chart {
  background: rgb(255, 255, 255);
  padding: 5px 10px;
  height: 260px;
}