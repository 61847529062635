#zwb {

  .form-control {
    border: 1px solid #aaa;
  }

  .form-group label.zwb-label {
    position: relative;
    top: initial;
    opacity: 1;
    max-height: initial;
    margin-top: -8px;
  }

	h2 {
		font-size: 16px;
		font-weight: bold;
		margin: 0;
	}

	table {
		th:last-child, td:last-child {
			text-align: right;
		}
	}
}

h1.zwb-headline {
  text-align: center;
  font-size: 20px;
  margin-bottom: 3rem;
	font-weight: normal;
}

#zwb_sig_preview {
  float: left;
  height: 100px;
  width: 220px;
  border: 1px solid #aaa;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  background: #f2f2f2;
}

#zwb_sig_form {
  margin-left: 240px;
}

#zwb_sig_form .zwb-label {
  font-weight: normal;
  font-size: 1.2rem;
  padding-bottom: 2rem;
}

#zwb_doubles_warning {
  padding: 20px;
	margin-bottom: 5rem;
	margin-top: 4rem;
}

#zwb_nav {
  margin-bottom: 2rem;

  nav {
    width: 100%;
    display: flex;
    flex-flow: row;
    justify-content: center;
  }

  a {
    background: #f2f2f2;
    color: #000;
    display: inline-block;
    position: relative;
    border-radius: 8px;
    margin-right: 6px;
    min-width: 170px;
    text-decoration: none;
  }

  a.active {
    background: #000;
    color: #fff;
  }

  a span {
    display: block;
    text-align: center;
    padding: 7px 20px;
    font-size: 16px;
  }


  a span:before {
    position: absolute;
    top: 0;
    right: -1px;
    display: block;
    content: '';
    width: 10px;
    height: 20px;
    background: linear-gradient(to top right, #f2f2f2 0%, #f2f2f2 50%, #fff 50%, #fff 100%);
  }

  a span:after {
    position: absolute;
    bottom: 0;
    right: -1px;
    display: block;
    content: '';
    width: 10px;
    height: 20px;
    background: linear-gradient(to bottom right, #f2f2f2 0%, #f2f2f2 50%, #fff 50%, #fff 100%);
  }

  a.active span:before {
    background: linear-gradient(to top right, #000 0%, #000 50%, #fff 50%, #fff 100%);
  }

  a.active span:after {
    background: linear-gradient(to bottom right, #000 0%, #000 50%, #fff 50%, #fff 100%);
  }
}

.zwb-callout {
  background: #e6f1f8;
  color: #000;
  padding: 20px;
  margin-bottom: 5rem;
  margin-top: 4rem;

  .form-control {
    background: #fff;
  }

	&.secondary {
		background: $color-gray;
	}
}

.btn-back {
  display: block;
  position: relative;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  width: calc(100% - 10px);
  margin-left: 10px;


  &:before {
    position: absolute;
    top: -1px;
    left: -11px;
    display: block;
    content: '';
    width: 10px;
    height: 19px;
    background: linear-gradient(to top left, #f2f2f2 0%, #f2f2f2 50%, #fff 50%, #fff 100%);
  }

  &:after {
    position: absolute;
    bottom: -1px;
    left: -11px;
    display: block;
    content: '';
    width: 10px;
    height: 19px;
    background: linear-gradient(to bottom left, #f2f2f2 0%, #f2f2f2 50%, #fff 50%, #fff 100%);
  }

  &.btn-primary:before {
    background: linear-gradient(to top left, $brand-primary 0%, $brand-primary 50%, transparent 50%, transparent 100%);
  }

  &.btn-primary:after {
    background: linear-gradient(to bottom left, $brand-primary 0%, $brand-primary 50%, transparent 50%, transparent 100%);
  }

  &.btn-primary:hover:before {
    background: linear-gradient(to top left, #2cbde8 0%, #2cbde8 50%, transparent 50%, transparent 100%);
  }

  &.btn-primary:hover:after {
    background: linear-gradient(to bottom left, #2cbde8 0%, #2cbde8 50%, transparent 50%, transparent 100%);
  }
}

.btn-next {
  display: block;
  position: relative;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  width: calc(100% - 10px);

  &:before {
    position: absolute;
    top: -1px;
    right: -11px;
    display: block;
    content: '';
    width: 10px;
    height: 19px;
    background: linear-gradient(to top right, #f2f2f2 0%, #f2f2f2 50%, #fff 50%, #fff 100%);
  }

  &:after {
    position: absolute;
    bottom: -1px;
    right: -11px;
    display: block;
    content: '';
    width: 10px;
    height: 19px;
    background: linear-gradient(to bottom right, #f2f2f2 0%, #f2f2f2 50%, #fff 50%, #fff 100%);
  }

  &.btn-primary:before {
    background: linear-gradient(to top right, $brand-primary 0%, $brand-primary 50%, transparent 50%, transparent 100%);
  }
	&[disabled].btn-primary:before {
    background: linear-gradient(to top right, $color-medium-gray 0%, $color-medium-gray 50%, transparent 50%, transparent 100%);
  }

  &.btn-primary:after {
    background: linear-gradient(to bottom right, $brand-primary 0%, $brand-primary 50%, transparent 50%, transparent 100%);
  }
	&[disabled].btn-primary:after {
		background: linear-gradient(to bottom right, $color-medium-gray 0%, $color-medium-gray 50%, transparent 50%, transparent 100%);
	}

  &.btn-primary:hover:before {
    background: linear-gradient(to top right, lighten($color-blue, 10%) 0%, lighten($color-blue, 10%) 50%, transparent 50%, transparent 100%);
  }

  &.btn-primary:hover:after {
    background: linear-gradient(to bottom right, lighten($color-blue, 10%) 0%, lighten($color-blue, 10%) 50%, transparent 50%, transparent 100%);
  }


}


.pt-1 {
  padding-top: 1rem;
}

.pt-2 {
  padding-top: 2rem;
}

.pt-3 {
  padding-top: 3rem;
}

.pt-4 {
  padding-top: 4rem;
}


.pb-1 {
  padding-bottom: 1rem;
}

.pb-2 {
  padding-bottom: 2rem;
}

.pb-3 {
  padding-bottom: 3rem;
}

.pb-4 {
  padding-bottom: 4rem;
}

.center-flex {
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

@media (min-width: 640px) {
  .row-flex {
    display: flex;
    flex-flow: row;
    justify-content: center;
    align-items: center;

    > div {
      float: none;
      height: 100%;
    }
  }
}

#zwb_form_selection {
  padding: 0 20px;

  .zwb-sel-type {
    color: #888;
    display: block;
    border: 1px solid #d3d3d3;
    padding: 4px 6px;
    text-decoration: none;
    white-space: nowrap;
    border-radius: 6px;
  }

  .zwb-sel-circle {
    display: inline-block;
    border: 1px solid #d3d3d3;
    height: 30px;
    width: 30px;
    line-height: 30px;
    border-radius: 50%;
    color: #fff;
    text-align: center;
    margin-right: 5px;
  }

  .zwb-sel-count {
    color: #000;
    font-weight: bold;
  }

  .zwb-sel-type:hover, .zwb-sel-type.active {
    border-color: #16a2cb;
    background: #16a2cb;
    color: #fff;

    > * {
      color: #fff;
    }

    .zwb-sel-circle {
      background: #fff;
      border-color: #fff;
      color: #16a2cb;
    }
  }

	// reset button for the search
	.input-group {
		span {
			position: relative;
			overflow: visible;
		}

		input[type="search"] {
			padding-right: 25px;

			// hide reset icon if the search in empty
			&:placeholder-shown + span a{
				display: none;
			}
		}

		#zwb_search_reset {
			font-size: 18px;
			line-height: 1;
			color: $color-dark-gray;
			position: absolute;
			top: 10px;
			left: -25px;
			z-index: 10;

			&:hover {
				text-decoration: none;
			}
		}
	}
}

// wrapper for previews
.zwb-preview-wrapper {
	background: $color-gray;
	overflow: hidden;
	margin-bottom: 5rem;

	.zwb-preview-nav {
		text-align: center;
		padding: 10px 20px;
		border-bottom: 1px solid $color-medium-gray;

		@media (min-width: $screen-md-min) {
			padding: 20px 40px;
			font-size: 18px;
		}

		a {
			@media (min-width: $screen-md-min) {
				margin: 0 20px;
			}

			&:hover, &:active {
				text-decoration: none;
				cursor: pointer;
			}
		}
	}

	.zwb-previews {
		padding: 10px;
		border-bottom: 1px solid $color-medium-gray;

		@media (min-width: $screen-md-min) {
			padding: 40px;
		}
	}

	.zwb-legend {
		margin-bottom: 0;
		display: block;
		min-height: 38px;

		@media (max-width: $screen-sm-max) {
			font-size: 14px;
		}
	}

	.zwb-edit-btn {
		border-bottom-left-radius: 0;
		border-bottom-right-radius: 0;
		margin: 0;
	}
}

// preview of document and mail
.zwb-preview {
  width: 100%;
	border: 0;
	box-shadow: 0 3px 5px 3px #ccc;
	min-height: 450px;
	background: #fff;
	margin-bottom: 20px;

	&:after {
		display: inline-block;
		content: '';
		position: absolute;
		top: 50%;
		left: 50%;
		z-index: 1;
		margin-left: -20px;
		margin-top: -20px;
		height: 40px;
		width: 40px;
		border: 3px solid lighten(#000, 60);
		border-right-color: transparent;
		border-radius: 50%;
		animation: rotate-loading 1.5s linear 0s infinite normal;
	}

	// special settings for the preview of the document
	&.image {
		min-height: 600px;
		max-height: 800px;
		overflow: auto;
		position: relative;

		img {
			position: relative;
			z-index: 2;
			width: 100%;
			height: auto;
			background: #fff;
		}
	}

	iframe {
		width: 100%;
		height: 100%;
		border: 0;
		position: relative;
		z-index: 2;
	}
}

.zwb-edit-btn {
  float: right;
  margin-top: -1rem;
}

.zwb-legend {
  font-size: 16px;
  margin-bottom: 2rem;

	&.left {
		float: left;

		+ .btn {
			float: right;
			border-bottom-left-radius: 0;
			border-bottom-right-radius: 0;
			//background: $color-medium-gray;
			//color: $color-darker-gray;
			//border-color: transparent;

			i {
				display: inline-block;
				margin-right: 12px;
				position: relative;
				top: 2px;
			}
		}
	}

	+ .form-group {
		margin-top: 3.5rem;
	}
}


#zwb_sig_preview img {
  max-width: 100%;
  max-height: 100%;
  display: inline-block;
}

.zwb-circle {
  display: inline-block;
  border: 1px solid #d3d3d3;
  height: 20px;
  width: 20px;
  line-height: 20px;
  border-radius: 50%;
  color: #fff;
  text-align: center;
  margin-left: 5px;
  font-size: 11px;
  vertical-align: top;
}

.zwb-circle.active {
  color: inherit;
}

.checkbox-cell { // th, td
  font-size: 16px !important;

  .checkbox {
    margin: 0;

    label {
      min-height: 1rem;
			position: static;
    }

    label:before {
      width: 14px;
    }
  }
}

.selected-only .checkbox-cell {
  display: none;
}

.zwb-hr {
  border-top: 2px solid #ddd;
	margin: 2rem 0;
}

#zwb_contacts_selected {
  padding: 10px;
  text-align: center;
  background: rgba(255, 255, 128, 1);
  color: #666666;
  margin-bottom: 2rem;
}

.zwb_recipients_loading {
  text-align: center;
  padding: 20px;
}

.contact-table {
  margin-bottom: 4rem;

  th {
    font-size: 12px;
    font-weight: normal;
  }

  td {
    color: #333;
  }
}

.contact-row {
  position: relative;

  &:nth-child(even) td {
    background: rgb(255, 255, 255)
  }

  &:nth-child(odd) td {
    background: rgb(242, 242, 242)
  }

  .zwb-toggle-trx {
    text-decoration: none;
    color: inherit;
  }

  .zwb-details-wrap {
    position: absolute;
    right: 0;
    background: #fff;
    padding: 0;
    z-index: 10;
    min-width: 40vw;

    table {
      margin: 0;
      padding: 0;
    }
  }
}

.zwb-details-wrap {
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.2);

  .donations-table {
    td {
      background: rgb(255, 255, 255) !important
    }
  }
}


#zwb_projects {
  border-width: 2px;
  border-style: solid;
  border-color: rgba(242, 242, 242, 1);
  border-radius: 4px;
  padding: 14px 20px;

  .checkbox-wrapper {
    text-align: left;
  }

  label {
    font-weight: normal;
    color: #9d9d9d;
  }

  input:checked + label {
    color: #333333;
  }
}

.zwb-skip-warning {
  padding-top: 10px;
  color: #9d9d9d;
  line-height: 1.2;
}

#zwb_doubles_intro {
  padding: 0 20px 5px 20px;
}

#zwb_double_nav {
  padding-bottom: 1rem;

}

#zwb_master {
  background: #eee;
  padding: 20px;
	margin-bottom: 30px;

  td {
    border: 0;
  }

  td:first-child {
    padding-left: 0;
  }

  td:last-child {
    padding-right: 0;
  }

  table {
    margin: 0;
  }

  input, select {
    background: #fff;
  }

	#zwb_doubles_buttons {
		text-align: center;
		margin: 20px 0 10px 0;

    a {
      display: inline-block;
      cursor: pointer;
      margin-left: 20px;
    }
	}
}

.zwb-dupes-tables {
  th.checkbox-cell .checkbox {
    display: none;
  }
}


// summary
#zwb_summary_templates {
	.zwb-preview {
		height: 600px;
	}
}

.page_zwb_summary {
	.btn-next {
		text-align: left;

		i {
			padding-right: 10px;
		}
	}

	.zwb-legend {
		font-weight: normal;
	}
}

// overview
.overview {
	> .flexbox-wrapper {
		margin-bottom: 20px;
	}

	.zwb-callout {
		min-height: 100%;
	}
}

// template
.template {
	hr {
		margin-top: 30px;
	}

	.form-group {
		margin-bottom: 35px;
	}

	.checkbox.single {
		padding-left: 0;
		margin-top: -15px;
		margin-bottom: 30px;
	}
}

// dataset form
#zwb_dataset_form {
	 > .row > .col-sm-4:not(:last-child) {
		 border-right: 1px solid $color-dark-gray;
	 }
}
