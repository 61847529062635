/**
 * Styles for bootstrap selects
 * @deprecated bootstrap 3
 */
.bootstrap-select.form-control {


	.btn {
		border-radius: 0;
		border-width: 0;
	}
}
