#footer {
	padding: 20px;

	margin: 50px auto 0;
	max-width: 1800px;

	& > * {
		max-width: 1200px;
		margin: 0 auto;
	}

	a {
		color: $color-dark-gray;
	}
}
