
.welcome-message  {
  max-width: 600px;
  margin: 0 auto;

  p {
    margin: 20px 0 ;
  }
  .welcome-title {
    color: #000;
  }
  .welcome-button {
	  a {
		  i {
			  line-height: inherit;
			  padding-right: 5px;
			  position: relative;
			  top: 1px;
		  }
	  }
  }
}
