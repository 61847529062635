/**
 * Styles for the language switch button on the project settings page
 */
.language-switch {
	text-align: center;
	margin: 0 auto 20px auto;
	display: block;
	position: relative;

	&:after {
		content: "";
		height: 1px;
		background: $color-medium-gray;
		width: calc(100% + 40px);
		position: absolute;
		top: 50%;
		left: -20px;
		margin-top: -3px;
		z-index: 1;
	}

	.btn {
		border-top-width: 1px;
		border-bottom-width: 1px;
		z-index: 2;

		&:first-child {
			border-radius: 6px 0 0 6px;
			border-left-width: 1px;
		}
		&:last-child {
			border-radius: 0 6px 6px 0;
			border-right-width: 1px;
		}
	}

	// inactive languages
	$languages: de en fr es;
	@each $lang in $languages {
		.language-inactive-#{$lang} & {
			.btn[value="#{$lang}"] {
				background: lighten($color-medium-gray, 7%);
				color: lighten($color-dark-gray, 5%);

				&.active {
					background: lighten($brand-primary, 30%);
					border-color: lighten($brand-primary, 30%);
					color: #fff;
				}
			}
		}
	}
}