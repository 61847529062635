#feedback {
	padding-top: 0;

	.modal-body {
		padding-top: 0;
	}

	.modal-teaser {
		margin: 0 -30px;
		background: #daedfb;
		padding: 15px 60px;
		text-align: center;

		.btn {
			display: block;
			max-width: 80%;
			margin: auto;

			i {
				font-size: 18px;
				vertical-align: middle;
				display: inline-block;
				margin-right: 5px;
			}
		}

		+ .modal-teaser {
			background: transparent;
			margin-bottom: 30px;
		}
	}

	.feedback-types {
		margin-bottom: 25px;
		margin-top: -40px;
	}
	.btn-group .btn:not(.active) {
		background: $color-input-bg;
	}

	textarea {
		height: 100px;
	}

}

.dropdown-menu {
	border-radius: 0;
	padding: 5px;

	> li > a {
		padding: 7px 15px;
	}

	li.active > a, li:hover > a {
		background-color: $color-light-blue;
		color: #fff;
		cursor: pointer;
	}
}
