
.popover {
  border-radius: 0;
  border-color: $color-green;
  background: $color-green;
  color: #fff;
  font-size: 12px;
  min-width: 300px;
	padding: 5px;

	a {
		color: inherit;
		text-decoration: underline;
	}

	&:after {
		@include twingleIcon();
		content: "\eafa";
		position: absolute;
		top: 5px;
		right: 5px;
		font-size: 10px;
		cursor: pointer;
	}
}

.inline-help {
  font-weight: normal;
  color: $color-blue;
  font-size: 90%;
	text-decoration: none;
	cursor: help;

	&:hover {
		text-decoration: none;
	}
}

.hint {
	display: block;
	margin-bottom: 30px;
	font-style: italic;

	&.center {
		text-align: center;
	}
}
